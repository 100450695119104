import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetWorkloadsFromAggregation, GetWorkloadsFromAggregationResponse } from "../../../../../api/fetcher";
import ExportCSV from "../../../../../components/exportCSV/ExportCSV";
import ExportCSVOptionButton from "../../../../../components/ExportCSVOptionButton";
import { Field } from "../../utils";
import useAggregationFilters from "../hooks/useAggregationFilters";

const BUTTON_TITLE = "Aggregated workloads";

const getKeyValueSeparatedString = ({ arr }: { arr: { key?: string | undefined; value?: string | undefined }[] }) => {
  return arr
    ?.map((item) => {
      return `${String(item.key)}=${String(item.value)}`;
    })
    .join(", ");
};

const { queryKey, queryFn } = GetWorkloadsFromAggregation();

type CSVExportType = {
  id: string;
  amountOfWorkloads: number;
  annotations: string;
  clusterName: string;
  labels: string;
  namespace: string;
  onDemand: number;
  ownerCpuRequest: number;
  ownerMemoryRequest: number;
  replicas: number;
  savingsAvailable: number;
  spot: number;
  totalCost: number;
  workloadName: string;
  workloadType: string;
};

const ExportAggregationWorkloadsCSV = ({ isGpu }: { isGpu?: boolean }) => {
  const filters = useAggregationFilters();

  const [rows, setRows] = useState<CSVExportType[]>([]);

  const { data, isLoading, isError } = useQuery<GetWorkloadsFromAggregationResponse, Error>({
    queryKey: [queryKey, filters],
    queryFn: () =>
      queryFn({
        ...filters,
        gpuWorkloadsOnly: isGpu,
        multiCluster: true,
      }),
    enabled: Object.keys(filters).length > 0,
  });

  useEffect(() => {
    if (data?.aggregatedWorkloads) {
      const dataToSet = data?.aggregatedWorkloads.map((wl) => {
        const annotations = getKeyValueSeparatedString({ arr: wl.annotations });
        const labels = getKeyValueSeparatedString({ arr: wl.labels });

        return {
          ...wl,
          annotations,
          labels,
        };
      });

      setRows(dataToSet);
    }
  }, [data]);

  if (isLoading || isError) {
    return <ExportCSVOptionButton title={BUTTON_TITLE} disabled={true} />;
  }

  let columns = [
    "workloadName",
    "id",
    "namespace",
    "totalCost",
    "savingsAvailable",
    "ownerCpuRequest",
    "ownerMemoryRequest",
    "amountOfWorkloads",
    "annotations",
    "onDemand",
    "clusterName",
    "labels",
    "replicas",
    "spot",
    "workloadType",
  ] as (keyof CSVExportType)[];

  let columnsToRound = [
    "ownerCpuRequest",
    "ownerMemoryRequest",
    "replicas",
    "savingsAvailable",
    "totalCost",
  ] as (keyof CSVExportType)[];

  let columnsToSum = [
    "amountOfWorkloads",
    "onDemand",
    "ownerCpuRequest",
    "ownerMemoryRequest",
    "replicas",
    "savingsAvailable",
    "spot",
    "totalCost",
  ] as (keyof CSVExportType)[];

  const customColumnNames = {
    workloadName: "Workload Name",
    namespace: "Namespace",
    totalCost: "Total Cost",
    savingsAvailable: "Savings Available",
    ownerCpuRequest: "Owner CPU Request",
    ownerMemoryRequest: "Owner Memory Request",
    amountOfWorkloads: "Total Workloads",
    annotations: "Annotations",
    onDemand: "On-demand",
    clusterName: "Cluster",
    labels: "Labels",
    replicas: "Replicas",
    spot: "Spot",
    workloadType: "Workload Type",
    id: "ID",
  } as { [key in keyof CSVExportType]?: string };

  if (isGpu) {
    columns = columns.filter((column) => column !== "savingsAvailable");
    columnsToRound = columnsToRound.filter((column) => column !== "savingsAvailable");
    columnsToSum = columnsToSum.filter((column) => column !== "savingsAvailable");
    delete customColumnNames.savingsAvailable;
  }

  return (
    <div className="w-fit">
      <ExportCSV<CSVExportType>
        customButtonElement={<ExportCSVOptionButton title={BUTTON_TITLE} />}
        filename="aggregated_workloads.csv"
        columns={columns}
        data={rows}
        columnsToRound={columnsToRound}
        columnsToSum={columnsToSum}
        customColumnNames={customColumnNames}
        columnsToAverage={[Field.spot, Field.onDemand]}
      />
    </div>
  );
};

export default ExportAggregationWorkloadsCSV;
