import { Typography } from "@mui/material";
import { Area, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../utils/formatterUtils";
import { NO_OUTLINE } from "../../../utils/styleUtils";
import { RequestAndUsageByTimestampKey } from "../../componentUtils/overviewUtils";
import CustomTooltip, { CustomTooltipPayload } from "./CustomTooltip";
import Styles, { ALLOCATABLE_COLOR, AverageUsageColor, DIFF_COLOR } from "./Styles";
import { ChartComponents, FBATR_OPACITY, STROKE_WIDTH } from "./utils";
import { MAX_Y_AXIS_DOMAIN } from "../../../utils/graphUtils";

export const Pattern = ({ id }: { id?: string }) => (
  <pattern id={id ?? "color-stripe"} width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
    <rect width="2" height="8" fill={DIFF_COLOR} />
  </pattern>
);

const defaultValueFormatter = (value: number) => Math.round(value);

interface Props {
  title: React.ReactNode;
  viewPeriod?: string;
  info?: string;
  data?: RequestAndUsageByTimestampKey[];
  selectedChartComponents: ChartComponents[];
  getWasteValue?: (payload: CustomTooltipPayload) => number;
  includedChartComponents: ChartComponents[];
  requestHasLightFill?: boolean;
  uniqueId?: string;
  timeFormat?: string | undefined;
  tooltipTimeFormat?: string | undefined;
  valueFormatter?: (value: number) => number | string;
  disableAnimation?: boolean;
  overrideMaxY?: number;
  tickFormatter?: (value: number) => string;
}

const FomoChart = ({
  title,
  viewPeriod,
  info,
  data,
  selectedChartComponents,
  getWasteValue,
  includedChartComponents,
  requestHasLightFill,
  uniqueId = "color-stripe",
  timeFormat,
  tooltipTimeFormat,
  valueFormatter = defaultValueFormatter,
  disableAnimation,
  overrideMaxY,
  tickFormatter,
}: Props) => {
  const isIncluded = (element: ChartComponents) => includedChartComponents.includes(element);

  return (
    <div
      className="flex flex-col justify-center items-center py-4 pl-0 pr-10 h-full grow"
      style={{ overflow: "hidden" }}
    >
      <div className="relative flex flex-col justify-center items-center">
        <Typography variant="body2">{title}</Typography>
        {info && (
          <Typography variant="caption" className="text-text-darkGray absolute top-5 text-center w-[200px]">
            {info}
          </Typography>
        )}
      </div>
      <ResponsiveContainer width="100%" height="100%" className="mt-2">
        <ComposedChart data={data}>
          {/********** **********  **********  ********** 
           WasteFromRequestToRecommended
           ********** **********  **********  ***********/}
          {isIncluded(ChartComponents.WasteFromRequestToRecommended) &&
            selectedChartComponents.includes(ChartComponents.Request) && (
              <Area
                type="monotone"
                dataKey={ChartComponents.Request}
                strokeWidth={0}
                dot={false}
                fill={`url(#${uniqueId})`}
                fillOpacity={FBATR_OPACITY}
                isAnimationActive={!disableAnimation}
              />
            )}
          {isIncluded(ChartComponents.WasteFromRequestToRecommended) &&
            selectedChartComponents.includes(ChartComponents.Request) &&
            selectedChartComponents.includes(ChartComponents.Recommended) &&
            selectedChartComponents.includes(ChartComponents.Waste) && (
              <defs>
                <Pattern id={uniqueId} />
              </defs>
            )}
          {isIncluded(ChartComponents.WasteFromRequestToRecommended) &&
            isIncluded(ChartComponents.Recommended) &&
            selectedChartComponents.includes(ChartComponents.Recommended) && (
              <Area
                type="monotone"
                dataKey={ChartComponents.Recommended}
                dot={false}
                strokeWidth={0}
                fill="white"
                fillOpacity={1}
                isAnimationActive={!disableAnimation}
              />
            )}

          {/********** **********  **********  ********** 
           WasteFromAllocatableToRecommendedAllocatable
           ********** **********  **********  ***********/}
          {isIncluded(ChartComponents.WasteFromAllocatableToRecommendedAllocatable) &&
            selectedChartComponents.includes(ChartComponents.Allocatable) && (
              <Area
                type="monotone"
                dataKey={ChartComponents.Allocatable}
                strokeWidth={0}
                dot={false}
                fill={`url(#${uniqueId})`}
                fillOpacity={FBATR_OPACITY}
                isAnimationActive={!disableAnimation}
              />
            )}
          {isIncluded(ChartComponents.WasteFromAllocatableToRecommendedAllocatable) &&
            selectedChartComponents.includes(ChartComponents.Allocatable) &&
            selectedChartComponents.includes(ChartComponents.RecommendedAllocatable) &&
            selectedChartComponents.includes(ChartComponents.Waste) && (
              <defs>
                <Pattern id={uniqueId} />
              </defs>
            )}
          {isIncluded(ChartComponents.WasteFromAllocatableToRecommendedAllocatable) &&
            isIncluded(ChartComponents.RecommendedAllocatable) &&
            selectedChartComponents.includes(ChartComponents.RecommendedAllocatable) && (
              <Area
                type="monotone"
                dataKey={ChartComponents.RecommendedAllocatable}
                dot={false}
                strokeWidth={0}
                fill="white"
                fillOpacity={1}
                isAnimationActive={!disableAnimation}
              />
            )}
          {/********** **********  **********  ********** 
           WasteFromAllocatableToRequest
           ********** **********  **********  ***********/}
          {isIncluded(ChartComponents.WasteFromAllocatableToRequest) &&
            selectedChartComponents.includes(ChartComponents.Allocatable) && (
              <Area
                type="monotone"
                dataKey={ChartComponents.Allocatable}
                strokeWidth={0}
                dot={false}
                fill={`url(#${uniqueId})`}
                fillOpacity={FBATR_OPACITY}
                isAnimationActive={!disableAnimation}
              />
            )}
          {isIncluded(ChartComponents.WasteFromAllocatableToRequest) &&
            selectedChartComponents.includes(ChartComponents.Allocatable) &&
            selectedChartComponents.includes(ChartComponents.Request) &&
            selectedChartComponents.includes(ChartComponents.Waste) && (
              <defs>
                <Pattern id={uniqueId} />
              </defs>
            )}
          {isIncluded(ChartComponents.WasteFromAllocatableToRequest) &&
            isIncluded(ChartComponents.Request) &&
            selectedChartComponents.includes(ChartComponents.Request) && (
              <Area
                type="monotone"
                dataKey={ChartComponents.Request}
                dot={false}
                strokeWidth={0}
                fill="white"
                fillOpacity={1}
                isAnimationActive={!disableAnimation}
              />
            )}
          {/********** **********  **********  ********** 
           Main Section
           ********** **********  **********  ***********/}
          {isIncluded(ChartComponents.Usage) && selectedChartComponents.includes(ChartComponents.Usage) && (
            <Area
              type="monotone"
              dataKey={ChartComponents.Usage}
              stroke={AverageUsageColor}
              strokeWidth={STROKE_WIDTH}
              dot={false}
              fill={AverageUsageColor}
              fillOpacity={FBATR_OPACITY}
              isAnimationActive={!disableAnimation}
            />
          )}
          {isIncluded(ChartComponents.Allocatable) && selectedChartComponents.includes(ChartComponents.Allocatable) && (
            <Area
              type="monotone"
              dataKey={ChartComponents.Allocatable}
              stroke={ALLOCATABLE_COLOR}
              strokeWidth={STROKE_WIDTH}
              dot={false}
              fillOpacity={0}
              isAnimationActive={!disableAnimation}
            />
          )}
          {selectedChartComponents.includes(ChartComponents.Request) && (
            <Area
              type="monotone"
              dataKey={ChartComponents.Request}
              stroke={Styles.currentRequest.stroke}
              strokeWidth={STROKE_WIDTH}
              dot={false}
              fill={requestHasLightFill ? Styles.currentRequest.stroke : undefined}
              fillOpacity={requestHasLightFill ? FBATR_OPACITY : 0}
              isAnimationActive={!disableAnimation}
            />
          )}
          {selectedChartComponents.includes(ChartComponents.GpuUsedMemory) && (
            <Area
              type="monotone"
              dataKey={ChartComponents.GpuUsedMemory}
              stroke={Styles.gpuUsedMemory.stroke}
              strokeWidth={STROKE_WIDTH}
              dot={false}
              fill={Styles.gpuUsedMemory.stroke}
              fillOpacity={FBATR_OPACITY}
              isAnimationActive={!disableAnimation}
              label={"Test"}
            />
          )}
          {selectedChartComponents.includes(ChartComponents.GpuTotalMemory) && (
            <Area
              type="monotone"
              dataKey={ChartComponents.GpuTotalMemory}
              stroke={Styles.gpuTotalMemory.stroke}
              strokeWidth={STROKE_WIDTH}
              dot={false}
              fill={requestHasLightFill ? Styles.gpuTotalMemory.stroke : undefined}
              fillOpacity={requestHasLightFill ? FBATR_OPACITY : 0}
              isAnimationActive={!disableAnimation}
              label={"Test"}
            />
          )}
          {selectedChartComponents.includes(ChartComponents.GpuSmActive) && (
            <Area
              type="monotone"
              dataKey={ChartComponents.GpuSmActive}
              stroke={Styles.gpuSmActive.stroke}
              strokeWidth={STROKE_WIDTH}
              dot={false}
              fill={Styles.gpuSmActive.stroke}
              fillOpacity={FBATR_OPACITY}
              isAnimationActive={!disableAnimation}
              label={"Test"}
            />
          )}
          {selectedChartComponents.includes(ChartComponents.GpuUtilization) && (
            <Area
              type="monotone"
              dataKey={ChartComponents.GpuUtilization}
              stroke={Styles.gpuUtilization.stroke}
              strokeWidth={STROKE_WIDTH}
              dot={false}
              fill={requestHasLightFill ? Styles.gpuUtilization.stroke : undefined}
              fillOpacity={requestHasLightFill ? FBATR_OPACITY : 0}
              isAnimationActive={!disableAnimation}
              label={"Test"}
            />
          )}
          {isIncluded(ChartComponents.RecommendedAllocatable) &&
            selectedChartComponents.includes(ChartComponents.RecommendedAllocatable) && (
              <Area
                type="monotone"
                dataKey={ChartComponents.RecommendedAllocatable}
                stroke={Styles.recommendedAllocatable.stroke}
                strokeWidth={STROKE_WIDTH}
                dot={false}
                fillOpacity={0}
                isAnimationActive={!disableAnimation}
              />
            )}
          {isIncluded(ChartComponents.Recommended) && selectedChartComponents.includes(ChartComponents.Recommended) && (
            <Area
              type="monotone"
              dataKey={ChartComponents.Recommended}
              stroke={Styles.recommendedRequest.stroke}
              strokeWidth={STROKE_WIDTH}
              dot={false}
              fillOpacity={0}
              isAnimationActive={!disableAnimation}
            />
          )}
          <XAxis
            dataKey="timestamps"
            interval={Math.floor((data && data.length / 6) ?? 1)}
            axisLine={{
              stroke: "black",
            }}
            strokeWidth={STROKE_WIDTH}
            opacity={1}
            ticks={data && data.length ? undefined : []}
            style={{ fontSize: "x-small" }}
            tickFormatter={(value) => {
              if (!data?.length) {
                return "";
              }
              const epochValue = adjustedDayjs(String(value)).unix();
              return getTimeFormatFromEpochAndPeriodInHours(epochValue, viewPeriod ?? 60 * 14);
            }}
          />
          <YAxis
            style={{
              fontSize: "10px",
            }}
            axisLine={{
              stroke: "black",
            }}
            strokeWidth={STROKE_WIDTH}
            opacity={1}
            domain={[
              0,
              (dataMax: number) => Number(overrideMaxY ? Math.max(overrideMaxY, dataMax) : dataMax * MAX_Y_AXIS_DOMAIN),
            ]}
            tickFormatter={(tick: number) => String((tickFormatter ?? valueFormatter)(tick))}
          />
          <CartesianGrid strokeDasharray="2 2" stroke="black" opacity={0.1} />
          <Tooltip
            wrapperStyle={NO_OUTLINE}
            content={
              <CustomTooltip
                getWasteValue={getWasteValue}
                includedChartComponents={includedChartComponents}
                selectedChartComponents={selectedChartComponents}
                valueFormatter={valueFormatter}
                timeFormat={tooltipTimeFormat ?? timeFormat}
              />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FomoChart;
