import { useQuery } from "@tanstack/react-query";
import {
  getAllNamespacesLabels,
  GetAllNamespacesLabelsResponse,
} from "../../../api/fetcher";

const KEY_AND_VALUE_COMBINATIONS_TO_EXCLUDE = ["app.kubernetes.io/instance=scaleops"];

const { queryKey, queryFn } = getAllNamespacesLabels();

const useGetAllNamespacesLabels = (isMultiCluster?: boolean) => {
  const { data, isLoading, error } = useQuery<GetAllNamespacesLabelsResponse>({
    queryKey: [queryKey, isMultiCluster ? "multi-cluster" : "single-cluster"],
    queryFn: () => queryFn(isMultiCluster),
  });

  if (error) {
    console.log("Error fetching labels", error);
    return [];
  }

  if (isLoading || !data?.allNamespacesLabels) {
    return [];
  }

  const labels = data.allNamespacesLabels;
  let uniqueLabels = [...new Set(labels)];

  uniqueLabels = uniqueLabels.filter((label) => !KEY_AND_VALUE_COMBINATIONS_TO_EXCLUDE.includes(label));

  return uniqueLabels;
};

export default useGetAllNamespacesLabels;
