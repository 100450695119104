import { components } from "../../../api/schema";

export const getRoleDescription = (role: string) => {
  switch (role) {
    case "Admin":
      return "Authorized to perform any action on the platform including granting permissions to other users, creating policies and managing alerts, automating workloads and viewing all content.";
    case "Operator":
      return "Authorized to automate and attach policies to workloads and view all content.";
    case "Viewer":
      return "Authorized only to view all content.";
    default:
      return "";
  }
};

export enum AuthMethod {
  DISABLED = "Disabled",
  BUILT_IN = "Built-in",
  SSO_WITHOUT_AUTHORIZATION = "SSO",
  SSO_WITH_AUTHORIZATION = "SSO With Authorization",
}

export enum SSOIntegration {
  Okta = "Okta",
  OAuth2 = "OAuth2",
  Github = "Github",
  Google = "Google",
  Azure = "Azure",
  Openshift = "Openshift",
}

export const SSOIntegrationDocLink = {
  [SSOIntegration.Okta]: "/sso-and-login-security/oidc/",
  [SSOIntegration.OAuth2]: "/sso-and-login-security/oidc/",
  [SSOIntegration.Github]: "/sso-and-login-security/github/",
  [SSOIntegration.Google]: "/sso-and-login-security/google/",
  [SSOIntegration.Azure]: "/sso-and-login-security/azure/",
  [SSOIntegration.Openshift]: "/sso-and-login-security/openshift/",
};

export const getSSOIntegrationCommand = (
  integration: SSOIntegration,
  conf: components["schemas"]["ConfGetConfResponse"],
  includeRBACInCommand?: boolean
) => {
  const baseCommand = `helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set scaleopsToken=${conf?.token ?? "<SCALEOPS_TOKEN>"} \\
  --set clusterName=${conf?.clusterName ?? "<CLUSTER_NAME>"} \\`;

  switch (integration) {
    case SSOIntegration.Okta:
    case SSOIntegration.OAuth2:
      return `${baseCommand}
${
  includeRBACInCommand
    ? `  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN_GROUP> \\
`
    : ""
}  --set authProvider.oauth2.groupsClaim=<GROUPS> \\
  --set authProvider.oauth2.issuerUrl=<ISSUER_URL> \\
  --set authProvider.oauth2.clientID=<CLIENT_ID> \\
  --set authProvider.oauth2.clientSecret=<CLIENT_SECRET> \\ 
  # Optional - specify a list of allowed audience claims.
  --set authProvider.oauth2.audiences=api://default
`;
    case SSOIntegration.Github:
      return `${baseCommand}
${
  includeRBACInCommand
    ? `  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN_GROUP> \\
`
    : ""
}  --set authProvider.github.clientID=<CLIENT_ID> \\
  --set authProvider.github.clientSecret=<CLIENT_SECRET> \\
  # Optinal - for Enterprise Configuration for On-Premises GitHub
  --set authProvider.github.hostName=<GITHUB-ENTERPRISE-HOSTNAME> \\
  # Optinal - for Enterprise Configuration for On-Premises GitHub
  --set authProvider.github.rootCA=<GITHUB-ENTERPRISE-ROOT-CA>
`;
    case SSOIntegration.Google:
      return `${baseCommand}
${
  includeRBACInCommand
    ? `  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN_GROUP> \\
`
    : ""
}  --set authProvider.google.clientID=<CLIENT_ID> \\
  --set authProvider.google.clientSecret=<CLIENT_SECRET> \\
  --set-file googleServiceAccountCredentials=/full/path/to/downloads/credentials.json
`;
    case SSOIntegration.Openshift:
      return `${baseCommand}
${
  includeRBACInCommand
    ? `  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN_GROUP> \\
`
    : ""
}  --set authProvider.openshift.clientID=<CLIENT_ID> \\
  --set authProvider.openshift.clientSecret=<CLIENT_SECRET> \\
  # Optinal - for OpenShift cluster custom TLS CA
  --set authProvider.openshift.insecureCA=true 
`;
    case SSOIntegration.Azure:
      return `${baseCommand}
${
  includeRBACInCommand
    ? `  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN_GROUP> \\
`
    : ""
}  --set authProvider.oauth2.issuerUrl=<ISSUER_URL> \\
  --set authProvider.oauth2.clientID=<CLIENT_ID> \\
  --set authProvider.oauth2.clientSecret=<CLIENT_SECRET> \\
  --set authProvider.oauth2.skipIssuerCheck=true \\
  --set authProvider.oauth2.groupsClaim=<GROUPS>
`;
    default:
      return "";
  }
};
