import { components } from "../../../../api/schema";
import { SCALEOPS_COLORS } from "../../../../colors";

const workloadHasTagFncByTerm = (term: string) => {
  const fnc = (workload: components["schemas"]["UtilsWorkload"]) =>
    !!(workload.smartPolicyWorkloadType && workload.smartPolicyWorkloadType === term);
  return fnc;
};

/*  
    Dear developer,
    If you add value here, please add also color under SCALEOPS_COLORS.workloadTags.
    Thanks. :)
*/
const SEARCH_TERMS_ARRAY = [
  ["ElasticSearch", "Elastic Search"],
  ["Mysql"],
  ["Kafka"],
  ["RabbitMQ"],
  ["Redis"],
  ["Postgres"],
  ["Mongo"],
  ["Flink"],
  ["Spark"],
  ["Memcached"],
  ["MariaDB"],
  ["ArgoController", "Argo Controller"],
  ["DockerRegistry", "Docker Registry"],
  ["Unevictable by Annotation"],
  ["Unevictable by PDB"],
  ["ScaleOps"],
    ["KubeSystem","Kube System"],
    ["Rubrikjob","Rubrik Job"],
    ["Jenkinsjob","Jenkins Job"],
    ["Githubrunner","Github Runner"],
    ["External DNS","External DNS"],
    ["Vertex Pipeline","Vertex Pipeline"],
    ["Telepresence Traffic Manager","Telepresence Traffic Manager"],
    ["Argo","ArgoCD"],
    ["CronJob","CronJob"],
    ["CustomCronJob","Custom CronJob"],




];

export type TagArray = {
  title: string;
  background: string;
  color: string;
  workloadHasTagFnc: (workload: components["schemas"]["UtilsWorkload"]) => boolean;
};

export const tagArray: TagArray[] = [
  {
    title: "Java",
    background: SCALEOPS_COLORS.events.throttling,
    color: "white",
    workloadHasTagFnc: (workload) => !!workload.isJavaWorkload,
  },
  ...SEARCH_TERMS_ARRAY.map((term, index) => ({
    title: term[1] ?? term[0],
    background: SCALEOPS_COLORS.workloadTags[index],
    color: "black",
    workloadHasTagFnc: workloadHasTagFncByTerm(term[0]),
  })),
];
