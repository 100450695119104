import { AuthMethod, SSOIntegration } from "./utils";

interface GetIntegrationCommandProps {
  currentIntegration: AuthMethod;
  selectedIntegration: AuthMethod;
  selectedSsoIntegration?: SSOIntegration;
  token?: string;
  username?: string;
  password?: string;
  hideToken?: boolean;
}

export const getIntegrationCommand = ({
  currentIntegration,
  selectedIntegration,
  selectedSsoIntegration,
  token,
  username,
  password,
  hideToken,
}: GetIntegrationCommandProps) => {
  let tokenToDisplay = "";
  switch (true) {
    case !!hideToken && !!token && token.length > 0:
      tokenToDisplay = String(token).replace(/./g, "*");
      break;
    case !!token:
      tokenToDisplay = String(token);
      break;
    default:
      tokenToDisplay = "<SCALEOPS-TOKEN>";
  }

  let passwordToDisplay = "";
  switch (true) {
    case !!hideToken && !!password && password.length > 0:
      passwordToDisplay = String(password).replace(/./g, "*");
      break;
    case !!password:
      passwordToDisplay = String(password);
      break;
    default:
      passwordToDisplay = "<PASSWORD>";
  }

  switch (true) {
    case selectedIntegration === AuthMethod.DISABLED && currentIntegration !== AuthMethod.DISABLED:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
    --set authProvider.provider=none
`;
    case selectedIntegration === AuthMethod.BUILT_IN &&
      (currentIntegration === AuthMethod.SSO_WITH_AUTHORIZATION ||
        currentIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION):
      return `
### Save the following credentials before executing the command
### Username: ${username ?? ""} 
### Password: ${passwordToDisplay}
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
    --set authProvider.provider=builtInAuth
`;
    case selectedIntegration === AuthMethod.BUILT_IN && currentIntegration === AuthMethod.DISABLED:
      return `
### Save the following credentials before executing the command
### Username: ${username ?? ""} 
### Password: ${passwordToDisplay}
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
    --set authProvider.provider=builtInAuth
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION && selectedSsoIntegration === SSOIntegration.Okta:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=okta \\
  --set authProvider.okta.issuerUrl=<ISSUER-URL> \\
  --set authProvider.okta.clientID=<CLIENT-ID> \\
  --set authProvider.okta.clientSecret=<CLIENT-SECRET>
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.OAuth2:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=oauth2 \\
  --set authProvider.oauth2.issuerUrl=<ISSUER-URL> \\
  --set authProvider.oauth2.clientID=<CLIENT-ID> \\
  --set authProvider.oauth2.clientSecret=<CLIENT-SECRET> \\
  --set authProvider.oauth2.scopes="{openid,profile,email}"
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Github:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=github \\
  --set authProvider.github.clientID=<CLIENT-ID> \\
  --set authProvider.github.clientSecret=<CLIENT-SECRET>
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Google:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=google \\
  --set authProvider.google.clientID=<CLIENT-ID> \\
  --set authProvider.google.clientSecret=<CLIENT-SECRET>
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Azure:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=azure \\
  --set authProvider.azure.tenantID=<TENANT-ID> \\
  --set authProvider.azure.clientID=<CLIENT-ID> \\
  --set authProvider.azure.clientSecret=<CLIENT-SECRET>
`;
    case selectedIntegration === AuthMethod.SSO_WITHOUT_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Openshift:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=openshift \\
  --set authProvider.openshift.clientID=<CLIENT-ID> \\
  --set authProvider.openshift.clientSecret=<CLIENT-SECRET>
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION &&
      currentIntegration !== AuthMethod.SSO_WITH_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Okta:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=okta \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN-GROUP> \\
  --set authProvider.okta.groupsClaim=<JWT-CLAIM-NAME-TO-USE> \\
  --set authProvider.okta.issuerUrl=<ISSUER-URL> \\
  --set authProvider.okta.clientID=<CLIENT-ID> \\
  --set authProvider.okta.clientSecret=<CLIENT-SECRET>
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION &&
      currentIntegration !== AuthMethod.SSO_WITH_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.OAuth2:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=oauth2 \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN-GROUP> \\
  --set authProvider.oauth2.groupsClaim=<JWT-CLAIM-NAME-TO-USE> \\
  --set authProvider.oauth2.issuerUrl=<ISSUER-URL> \\
  --set authProvider.oauth2.clientID=<CLIENT-ID> \\
  --set authProvider.oauth2.clientSecret=<CLIENT-SECRET>
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION &&
      currentIntegration !== AuthMethod.SSO_WITH_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Github:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=github \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN-GROUP> \\
  --set authProvider.github.clientID=<CLIENT-ID> \\
  --set authProvider.github.clientSecret=<CLIENT-SECRET>      
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION &&
      currentIntegration !== AuthMethod.SSO_WITH_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Google:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=google \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN-GROUP> \\
  --set authProvider.google.clientID=<CLIENT-ID> \\
  --set authProvider.google.clientSecret=<CLIENT-SECRET> \\
  --set-file googleServiceAccountCredentials=/full/path/to/downloads/credentials.json
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION &&
      currentIntegration !== AuthMethod.SSO_WITH_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Azure:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=azure \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN-GROUP> \\
  --set authProvider.azure.tenantID=<TENANT-ID> \\
  --set authProvider.azure.clientID=<CLIENT-ID> \\
  --set authProvider.azure.clientSecret=<CLIENT-SECRET> \\
  --set authProvider.azure.groupsClaim=groups
`;
    case selectedIntegration === AuthMethod.SSO_WITH_AUTHORIZATION &&
      currentIntegration !== AuthMethod.SSO_WITH_AUTHORIZATION &&
      selectedSsoIntegration === SSOIntegration.Openshift:
      return `
helm repo add scaleops --username scaleops \\
  --password ${tokenToDisplay} \\
  https://raw.githubusercontent.com/scaleops-sh/helm-charts/main/ \\
  --force-update
helm show crds scaleops/scaleops | kubectl apply --force -f -
helm get values scaleops -n scaleops-system -oyaml | \\
  helm upgrade scaleops scaleops/scaleops -n scaleops-system -f - \\
  --set authProvider.provider=openshift \\
  --set authProvider.rolesEnabled=true \\
  --set authProvider.defaultAdminGroup=<ADMIN-GROUP> \\
  --set authProvider.openshift.clientID=<CLIENT-ID> \\
  --set authProvider.openshift.clientSecret=<CLIENT-SECRET>
`;
    default:
      return "";
  }
};
