import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  GetCustomNamespaceLabels,
  UpdateCustomNamespaceLabelsParams,
  UpdateCustomNamespaceLabels
} from "../../api/fetcher";
import { TOAST_SETTINGS } from "../../pages/Ingress/mutations/utils";

const { queryKey } = GetCustomNamespaceLabels();
const { queryFn } = UpdateCustomNamespaceLabels();

const useCustomNamespaceLabels = () => {
  const queryClient = useQueryClient();

  return useMutation((params: UpdateCustomNamespaceLabelsParams) => queryFn(params), {
    onError: (error: Error) => {
      console.log(`Error: ${error.message}`);
      toast.error(`Error saving custom namespace labels: ${error.message}`);
    },
    onSuccess: () => {
      toast.success("Custom namespace labels successfully updated!", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKey]).catch((error) => console.log(error));
    },
  });
};

export default useCustomNamespaceLabels;
