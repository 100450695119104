import { useQuery } from "@tanstack/react-query";
import AlertBar, { AlertBarTheme } from "../AlertBar";
import { GetScaleopsPodsHealth, GetScaleopsPodsHealthResponse } from "../../api/fetcher";
import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { components } from "../../api/schema";

const { queryKey, queryFn } = GetScaleopsPodsHealth();

const IMAGE_MATCH_CHART_ENABLED = true;
export type HealthStatus = components["schemas"]["HealthStatus"];

const AlertCode = styled(Typography)(() => ({
  backgroundColor: "#f6f8fa",
  borderRadius: "6px",
  color: "black",
  padding: "4px",
  width: "fit-content",
  fontSize: "12px",
  display: "inline",
}));

const OverviewAlertBars = () => {
  const { data: healthStatus } = useQuery<GetScaleopsPodsHealthResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
    refetchInterval: 1000 * 60,
  });

  const alertData: ReactNode[] = [];
  if (healthStatus?.isFreshInstall) {
    return null;
  }

  if (healthStatus?.prometheus?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.prometheus, "prometheus-server"));
  } else if (healthStatus?.admissionController?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.admissionController, "admissions"));
  } else if (healthStatus?.kubeStateMetrics?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.kubeStateMetrics, "kube-state-metrics"));
  }
  if (healthStatus?.updater?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.updater, "updater"));
  }
  if (healthStatus?.recommender?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.recommender, "recommender"));
  }
  if (healthStatus?.agentController?.healthy === false) {
    alertData.push(getPodStatusReason(healthStatus?.agentController, "agent"));
  }
  if (IMAGE_MATCH_CHART_ENABLED && healthStatus?.imageMatchesChart === false) {
    alertData.push(
      <>
        The <b>scaleops image doesn't match the chart version</b>. This can cause unexpected behavior. Please verify
        ScaleOps is installed correctly.
      </>
    );
  }
  let title = <>Health Check</>;

  if (healthStatus?.trialExpired === true) {
    title = <>⏳ Your ScaleOps trial period will expire soon!</>;
  }

  return (
    ((alertData.length || healthStatus?.trialExpired === true) && (
      <div className="sticky self-start top-0 z-50 w-full">
        <AlertBar title={title} description={alertData} theme={AlertBarTheme.LightRed} />
      </div>
    )) ||
    null
  );
};

const getPodStatusReason = (status: HealthStatus, name: string) => {
  switch (status.type?.Status) {
    case "Affinity Conflict":
      return (
        <>
          <b>scaleops-{name}</b> is pending due to a volume node affinity conflict.
        </>
      );
    case "CrashLoopBackOff":
      return (
        <>
          <b>scaleops-{name}</b> is currently in CrashLoopBackOff state, affecting ScaleOps performance.
        </>
      );
    case "Component Disabled":
      return (
        <>
          <b>scaleops-{name}</b> is currently offline as it was installed with the{" "}
          <AlertCode>{name}.enabled=false</AlertCode> flag. <br />
          To re-enable admission, reinstall ScaleOps with Helm flag <AlertCode>--set {name}.enabled=true</AlertCode>.
        </>
      );
    case "Missing Deployment":
      return (
        <>
          <b>scaleops-{name}</b> deployment is missing, affecting ScaleOps performance.
        </>
      );
    case "Pod Optimization Disabled":
      return (
        <>
          <b>scaleops-{name}</b> is unable to mutate pods, which cause recommendations not to be applied.
        </>
      );
    case "Prometheus Metrics Error":
      return (
        <>
          <b>scaleops-{name}</b> is unable to fetch metrics, affecting ScaleOps performance.
        </>
      );
    case "Persistent Volume Was Deleted":
      return (
        <>
          <b>scaleops-{name}</b> is stuck on ContainerCreating because volume was deleted.
        </>
      );
    case "Pending":
      return (
        <>
          <b>scaleops-{name}</b> is pending and unable to run, affecting ScaleOps performance.
        </>
      );
    case "Failed To Create Because Security Context Denied Host Path":
      return (
        <>
          <b>scaleops-{name}</b> failed to create because security context denied host path.
        </>
      );
    case "Zero Pods":
      return (
        <>
          <b>scaleops-{name}</b> pod doesn’t exist, affecting ScaleOps performance.
        </>
      );
    case "Unable To Load Config":
      return (
        <>
          <b>scaleops-{name}</b> is failing because unable to load configuration.
        </>
      );
    case "Stuck On Container Creating":
      return (
        <>
          <b>scaleops-{name}</b> is stuck on container creating.
        </>
      );
    case "Stuck On Container Terminating":
      return (
        <>
          <b>scaleops-{name}</b> is stuck on container terminating.
        </>
      );
    case "Missing Mutating Webhook Configuration":
      return (
        <>
          <b>scaleops-{name}</b> is missing mutating webhook configuration.
        </>
      );
    case "Health Check Pod Failed Because Blocked By OPA":
      return (
        <>
          <b>scaleops-{name}</b> health check pod failed to create because blocked by OPA.
        </>
      );
    case "Health Check Pod Failed Because Other Admissions":
      return (
        <>
          <b>scaleops-{name}</b> health check pod failed to create because other admission{" "}
          <AlertCode>{status.type.Message}</AlertCode> has <AlertCode>failurePolicy: Fail</AlertCode> policy.
        </>
      );
    case "Failed Scheduling":
      return (
        <>
          <b>scaleops-{name}</b> failed to schedule.
        </>
      );
    case "Misconfigured Cert Manager":
      return (
        <>
          <b>scaleops-{name}</b> misconfigured cert manager.
        </>
      );
    case "Mutating Webhook Unknown Service":
      return (
        <>
          Mutating webhook <AlertCode>scaleops-mutating-webhook-configuration</AlertCode> linked to an unknown service.
        </>
      );
    case "QueryError":
      return (
        <>
          <b>scaleops-{name}</b> metrics are missing, this may affect recalculated recommendations and platform data.
        </>
      );
    case "Kube State Metrics Pod Metrics":
      return (
        <>
          <b>scaleops-{name}</b> pod metrics are missing, this may affect recalculated recommendations and platform
          data.
        </>
      );
    case "Prometheus Failed To Scrape Target":
      return (
        <>
          <b>scaleops-{name}</b> failed to scrape target <b>scaleops-{name}</b>.
        </>
      );
    case "ErrImagePull":
      return (
        <>
          <b>scaleops-{name}</b> is in ErrImagePull state.
        </>
      );
    case "ImagePullBackOff":
      return (
        <>
          <b>scaleops-{name}</b> is in ImagePullBackOff state.
        </>
      );
    case "InvalidImageName":
      return (
        <>
          <b>scaleops-{name}</b> is in InvalidImageName state.
        </>
      );
    case "Failed Create Pod Sand Box":
      return (
        <>
          <b>scaleops-{name}</b> failed to create pod sandbox.
        </>
      );
    case "Unsupported Container Storage Interface Driver":
      return (
        <>
          <b>scaleops-{name}</b> does not support CSI <AlertCode>{status.type.Message}</AlertCode>.
        </>
      );
    case "Failed Attach Volume":
      return (
        <>
          <b>scaleops-{name}</b> is pending because of failure to attach a volume. Please try a different storage class
          by installing with additional helm flag{" "}
          <AlertCode>
            --set prometheus.server.persistentVolume.storageClass=
            {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
          </AlertCode>
        </>
      );
    case "No Storage Class":
      return (
        <>
          <>
            <b>scaleops-{name}</b> is pending because no storage class is detected. <br />
            Please install scaleops with the additional helm flag <AlertCode>--set prometheus.localPath=true</AlertCode>
          </>
        </>
      );
    case "No Matching Storage Class":
      return (
        <>
          <b>scaleops-{name}</b> is pending because no matching storage class is configured. <br />
          Please install ScaleOps with the additional helm flag{" "}
          <AlertCode>
            --set prometheus.server.persistentVolume.storageClass=
            {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
          </AlertCode>
        </>
      );
    case "No Default Storage Class":
      return (
        <>
          <b>scaleops-{name}</b> is pending because no default storage class is configured. <br />
          Please install ScaleOps with the additional helm flag{" "}
          <AlertCode>
            --set prometheus.server.persistentVolume.storageClass=
            {status.type?.Message ? `<${status.type?.Message}>` : "<EXITING STORAGE CLASS>"}
          </AlertCode>
        </>
      );
    case "Attachment Is Being Deleted":
      return (
        <>
          <b>scaleops-{name}</b> is pending because volume attachment is being deleted. <br />
          Please verify ScaleOps is installed correctly.
        </>
      );
    case "Can't Reach Internal Service":
      return (
        <>
          <b>scaleops-{name}</b> can't reach the internal service <b>{status.type.Message}</b>.
        </>
      );
    default:
      return (
        <>
          <b>scaleops-{name}</b> is unready, affecting ScaleOps performance.
        </>
      );
  }
};

export default OverviewAlertBars;
